@import url(https://fonts.googleapis.com/css?family=Oxygen:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Oxygen', sans-serif
}

.App {
  text-align: center;
}

.stack {
  display: flex;
  flex-direction: column;
}

.appContent{
  background-color: #151B26;
  justify-content: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
  overflow: none;
  text-align: center;
}

.underCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  opacity: 0;
  -webkit-animation: Rise 1.5s .4s forwards ease;
          animation: Rise 1.5s .4s forwards ease;
}

.labels {
  display: grid;
  grid-gap: 40px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  font-size: 1.33rem;
  grid-template-columns: repeat(3, 165px);
  position: relative;
  max-width: 100%;
  overflow: scroll;
}

.shortTerm {
  display: flex;
  position: relative;
  align-items: center;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}


@-webkit-keyframes Rise {
  0% {
    opacity: 0!important;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible ;
  }
}


@keyframes Rise {
  0% {
    opacity: 0!important;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible ;
  }
}

@-webkit-keyframes FadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes FadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media (max-width: 900px) {
  .underCard {
    flex-direction: column-reverse;
  }

  .labels {
    margin-bottom: 24px;
    padding: 12px 0px;
  }
}

@media (max-width: 720px) {
  .App-header {
    padding-bottom: 50px;
  }

}

@media (max-width: 600px) {
  .labels {
    grid-gap: 20px;
  }

  .shortTerm {
    width: 75%;
  }

}
@-webkit-keyframes Grow {
  0% {
    width: 0;
  }

  100% {
  }
}
@keyframes Grow {
  0% {
    width: 0;
  }

  100% {
  }
}
